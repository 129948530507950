define('web/models/article', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    subTitle: _emberData.default.attr('string'),
    body: _emberData.default.attr('string'),
    author: _emberData.default.attr('string'),
    status: _emberData.default.attr('number'),
    imageUrl: _emberData.default.attr('string'),
    heroImageUrl: _emberData.default.attr('string'),
    publishedAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    handle: _emberData.default.attr('string'),
    user: _emberData.default.belongsTo('user'),
    // category: DS.belongsTo('category'),
    page: _emberData.default.belongsTo('page'),
    articleItems: _emberData.default.hasMany('article-item'),
    preview: Ember.computed('body', function () {
      let tmp = document.createElement("DIV");
      tmp.innerHTML = this.get('body');
      let res = tmp.textContent || tmp.innerText || '';
      res.replace('\u200B', ''); // zero width space
      res = res.trim();
      return res;
    })
  });
});