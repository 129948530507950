define('web/controllers/search/results', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        queryParams: ['pt', 'b', 'cg', 'v', 'a'],
        params: Ember.computed('pt', 'b', 'cg', function () {
            let params = { pt: this.get('pt'), b: this.get('b'), cg: this.get('cg'), v: this.get('v'), a: this.get('a') };
            console.log(params);
            return params;
        })

    });
});