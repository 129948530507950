define('web/routes/application', ['exports', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _applicationRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
    currentUser: Ember.inject.service(),
    model: function () {
      return this.get('store').queryRecord('page', { id: 'homepage' });
    },
    beforeModel() {
      return this._loadCurrentUser();
    },

    sessionAuthenticated() {
      // this._super(...arguments);
      // this._loadCurrentUser();
    },

    _loadCurrentUser() {
      return this.get('currentUser').load().catch(() => this.get('session').invalidate());
    },
    actions: {
      getModalItem: function (itemId) {
        let _this = this;
        this.get('store').findRecord('item', itemId).then(item => {
          setTimeout(function () {
            _this.controller.set('modalItem', item);
          }, 1);
        });
      },
      setModalItem: function (item) {
        let _this = this;
        setTimeout(function () {
          _this.controller.set('modalItem', item);
        }, 1);
      },
      clearModalItem: function () {
        this.controller.set('modalItem', null);
      }
    }
  });
});