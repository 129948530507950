define('web/components/show-page', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      closeFullScreen: function () {
        this.get('bk').set('full', false);
      },
      toggleFullScreen: function (bk) {
        this.set('bk', bk);
        bk.toggleProperty('full');
      }
    }
  });
});