define('web/models/contact', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    department: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    salutation: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    fax: _emberData.default.attr('string'),
    homePhone: _emberData.default.attr('string'),
    cellPhone: _emberData.default.attr('string'),
    altPhone: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    altEmail: _emberData.default.attr('string'),
    reportsTo: _emberData.default.attr('string'),
    address1: _emberData.default.attr('string'),
    address2: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    zip: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    altAddress1: _emberData.default.attr('string'),
    altAddress2: _emberData.default.attr('string'),
    altCity: _emberData.default.attr('string'),
    altState: _emberData.default.attr('string'),
    altZip: _emberData.default.attr('string'),
    altCountry: _emberData.default.attr('string'),
    emailOptOutAt: _emberData.default.attr('date'),
    createdById: _emberData.default.attr('number'),
    updatedById: _emberData.default.attr('number'),
    lastActivityAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    user: _emberData.default.belongsTo('user'),
    alerts: _emberData.default.hasMany('alert'),
    marketing: _emberData.default.attr('boolean'),
    invoices: _emberData.default.attr('boolean'),
    accountsPayable: _emberData.default.attr('boolean'),
    generalContact: _emberData.default.attr('boolean'),
    purchasing: _emberData.default.attr('boolean'),
    pricing: _emberData.default.attr('boolean'),
    legal: _emberData.default.attr('boolean'),
    sms: Ember.computed(function () {
      let alert = this.get('alerts').filterBy('model', 'Order').filterBy('event', 'ready').get('firstObject');
      return alert && alert.get('sms');
    }),
    cellDisplay: Ember.computed('cellPhone', function () {
      let phone = this.get('cellPhone').replace(/\D/g, "");
      return `${phone.slice(0, 3)}.${phone.slice(3, 6)}.${phone.slice(6)}`;
    })
  });
});