define('web/models/cart', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    poNumber: _emberData.default.attr('string'),
    total: _emberData.default.attr('number'),
    contact: _emberData.default.attr('string'),
    company: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    address1: _emberData.default.attr('string'),
    address2: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    zip: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    shipVia: _emberData.default.attr('string'),
    shippingService: _emberData.default.belongsTo('shipping-service'),
    shipComment: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    comments: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    mustShipBy: _emberData.default.attr('date'),
    validatedAt: _emberData.default.attr('date'),
    user: _emberData.default.belongsTo('user', { readOnly: true }),
    customer: _emberData.default.belongsTo('customer', { readOnly: true }),
    // shipTo: DS.belongsTo('ship-to'),
    event: _emberData.default.belongsTo('event'),
    activePromos: _emberData.default.attr('boolean', { readOnly: true }),
    willCallStart: _emberData.default.attr('date', { readOnly: true }),
    shipByStart: _emberData.default.attr('date', { readOnly: true }),
    promo: _emberData.default.belongsTo('promo'),
    paymentToken: _emberData.default.belongsTo('paymentToken'),
    cartItems: _emberData.default.hasMany('cart-item'),
    orders: _emberData.default.hasMany('order'),
    cancelBackordersAt: _emberData.default.attr('date'),
    terms: _emberData.default.attr('string'),
    prop65Labeling: _emberData.default.attr('string'),
    inflateOption: Ember.computed('customer.alwaysInflate', 'cartItems.@each.canInflate', function () {
      return !this.get('customer.alwaysInflate') && this.get('cartItems').filterBy('canInflate').length > 0;
    }),
    prop65Needed: Ember.computed('customer.prop65', 'cartItems.@each.prop65', function () {
      let option = false;
      this.get('cartItems').forEach(function (qi) {
        if (qi.get('prop65')) {
          option = true;
        }
      });
      return option && this.get('customer.prop65');
    }),
    prop65Satisfied: Ember.computed('customer.prop65', 'prop65Labeling', 'cartItems.@each.prop65', function () {
      if (!this.get('customer.prop65')) {
        return true;
      }
      if (this.get('prop65Labeling')) {
        return true;
      }
      let option = true;
      this.get('cartItems').forEach(function (qi) {
        if (qi.get('prop65')) {
          option = false;
        }
      });
      return option;
    }),
    placed: Ember.computed('hasDirtyAttributes', 'status', function () {
      return this.get('status') === 'submitted' && !this.get('hasDirtyAttributes');
    }),
    cancelBackordersMin: Ember.computed(function () {
      let now = new Date();
      // return new Date(now.getFullYear(), now.getMonth()+1, now.getDate());
      return now;
    }),
    baseSubTotal: Ember.computed('cartItems.@each.basePrice', 'cartItems.@each.quantity', function () {
      return Math.round(this.get('cartItems').reduce(function (acc, value) {
        acc += value.get('basePrice') * value.get('quantity');
        return acc;
      }, 0) * 100) / 100;
    }),
    promoSubTotal: Ember.computed('promo.id', 'promo.promoItems.@each.discountCategory', 'cartItems.@each.basePrice', 'cartItems.@each.quantity', function () {
      if (!this.get('promo.id')) {
        return 0;
      }
      let promo = this.get('promo');
      return Math.round(this.get('cartItems').reduce(function (acc, value) {

        let itemTotal = 0;
        promo.get('promoItems').forEach(function (pi) {
          if (pi.get('promoVid') && pi.get('promoVid') === value.get('item.promoVid') || pi.get('item') && pi.get('item') == value.get('item') || pi.get('skuMatch') === 'any' && pi.get('sku').split(' ').indexOf(value.get('item.sku')) > -1 || pi.get('skuMatch') === 'equals' && pi.get('sku') === value.get('item.sku') || pi.get('skuMatch') === 'starts_with' && value.get('item.sku').slice(0, pi.get('sku.length')) == pi.get('sku') || pi.get('discountCategory') && pi.get('discountCategory.lenght') > 0 && pi.get('discountCategory') == value.get('item.discountCategory') || pi.get('productIds').indexOf(value.get('item.productTypeId')) > -1) {
            itemTotal = value.get('basePrice') * value.get('quantity');
          }
        });
        acc += itemTotal;
        return acc;
      }, 0) * 100) / 100;
    }),
    shipCommentPlaceholder: Ember.computed('shipVia', function () {
      if (this.get('shipVia') === 'Will Call') {
        return 'Pickup Time/Date';
      } else {
        return 'Additional Shipping Info';
      }
    }),
    willCallHours: Ember.computed('willCallDate', function () {
      console.log(this.get('willCallDate'));
      return [{ time: 'Before 10:00 am (will call closed)', disabled: true }, { time: '10:00 am - 11:00 am' }, { time: '11:00 - 12:00 pm' }, { time: '12:00 pm - 1:00 pm (will call closed)', disabled: true }, { time: '1:00 pm - 2:00 pm' }, { time: '2:00 pm - 3:00 pm' }, { time: 'After 3:00 pm (will call closed)', disabled: true }];
    })
  });
});