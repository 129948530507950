define('web/models/property-value', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    code: _emberData.default.attr('string'),
    icon: _emberData.default.attr('string'),
    position: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    property: _emberData.default.belongsTo('property')
  });
});