define('web/models/event', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    preorderStartsAt: _emberData.default.attr('date'),
    preorderEndsAt: _emberData.default.attr('date'),
    processingStartsAt: _emberData.default.attr('date'),
    shippingStartsAt: _emberData.default.attr('date'),
    shippingEndsAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    orders: _emberData.default.hasMany('order'),
    items: _emberData.default.hasMany('item'),
    active: Ember.computed('preorderStartsAt', 'preorderEndsAt', function () {
      console.log(this.get('preorderStartsAt') < new Date());
      console.log(this.get('preorderEndsAt') > new Date());
      return this.get('preorderStartsAt') < new Date() && this.get('preorderEndsAt') > new Date();
    })
  });
});