define('web/routes/orders/pickup', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    model: function (params) {
      return this.get('ajax').request(`/api/orders/pickup?token=${params.token}`);
    }
  });
});