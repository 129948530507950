define('web/components/new-customer', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    notify: Ember.inject.service(),
    didInsertElement: function () {
      let lead = this.get('store').createRecord('lead');
      this.set('model', lead);
    },
    actions: {
      submit: function () {

        if (!this.get('model.company') && !this.get('model.name') || !this.get('model.email') && !this.get('model.phone')) {
          this.get('notify').error('Company, name, email, and phone are required.');
          return;
        }

        let description = '';
        if (this.get('model.pfaId.length') > 0) {
          description += `PFA: ${this.get('model.pfaId')}\n`;
        }
        if (this.get('model.bpId.length') > 0) {
          description += `Balloon Planet: ${this.get('model.pcaId')}\n`;
        }
        this.set('model.description', description);

        let _this = this;

        this.get('model').save().then(() => {
          _this.get('notify').info('Your information has been submitted');
          _this.set('submitted', true);
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        });
      }
    }
  });
});