define('web/components/login-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    shoppingCart: Ember.inject.service(),
    router: Ember.inject.service(),
    actions: {
      login: function () {
        let _this = this;
        this.set('failed', false);
        let credentials = this.getProperties('user', 'password');
        this.get('session').authenticate('authenticator:token', { auth: credentials }).catch(reason => {
          _this.set('failed', reason.status);
        }).then(sess => {

          if (_this.get('failed') === 404) {
            return;
          }
          _this.get('currentUser').load();
          this.sendAction('toggleLogin');
          this.get('shoppingCart').getCart();
          this.get('router').transitionTo('index');
        });
      },
      forgotPassword: function () {
        this.get('router').transitionTo('forgot-password');
        this.sendAction('toggleLogin');
      },
      expired: function () {}
    }
  });
});