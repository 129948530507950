define('web/components/slider-block', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function () {
      let _this = this;
      setTimeout(function () {
        _this.set('loaded', true);
      }, 1000);
    },
    actions: {
      closeFullScreen: function () {
        this.get('bk').set('full', false);
      },
      toggleFullScreen: function (bk) {
        this.set('bk', bk);
        bk.toggleProperty('full');
      },
      addColor: function () {
        console.log(this.get('block.color'));
        this.$('.slick-slider').addClass(this.get('block.color'));
      }
    }
  });
});