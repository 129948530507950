define('web/services/shopping-cart', ['exports', 'ember-concurrency', 'moment'], function (exports, _emberConcurrency, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    notify: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    id: Ember.computed.alias('cart.id'),
    promo: Ember.computed.alias('cart.promo'),
    event: Ember.computed('cart.event.id', 'cart.cartItems.@each.quantity', 'cart.cartItems.@each.event', function () {
      let specialEvent = false;
      if (this.get('cart.cartItems')) {
        this.get('cart.cartItems').forEach(function (ci) {
          if (ci.get('item.event.active') && ci.get('quantity') > ci.get('item.available')) {
            specialEvent = ci.get('item.event');
          }
        });
        return specialEvent;
      }
    }),
    customer: Ember.computed.alias('cart.customer'),
    preventCheckout: Ember.computed('event', 'cart.event', function () {
      return this.get('cart.event.id') && this.get('availableTotal') < 100 || this.get('customer.chain.domains.length') > 0 && this.get('availableTotal') < 100;
    }),
    // paymentMethod: computed.alias('cart.user.customer.terms'),
    billingComplete: Ember.computed('paymentMethod', 'cart.paymentToken.id', function () {
      return this.get('paymentMethod') && (this.get('paymentMethod').indexOf('NET') > -1 || (this.get('paymentMethod').indexOf('CASH') > -1 || this.get('paymentMethod').indexOf('CREDIT CARD') > -1) && this.get('cart.paymentToken.id'));
    }),
    shippingComplete: Ember.computed('cart.shippingService.id', function () {
      return this.get('cart.shippingService.id') && this.get('cart.shippingService.id') * 1 > 0;
    }),
    chain: Ember.computed('customer.chainId', function () {
      return this.get('customer.chainId') == 1;
    }),
    freeFreightNeeded: Ember.computed('customer.noFreightAmount', 'availableTotal', function () {
      if (this.get('customer.noFreightAmount') && this.get('availableTotal') >= this.get('customer.noFreightAmount')) {
        return 0;
      } else if (this.get('customer.noFreightAmount')) {
        return this.get('customer.noFreightAmount') * 1 - this.get('availableTotal') * 1;
      }
    }),
    submitCart: (0, _emberConcurrency.task)(function* () {
      this.set('cart.status', 'submitted');
      try {
        let cart = yield this.get('cart').save();
        this.get('router').transitionTo('orders.success', cart.get('orders.firstObject.id'));
        this.set('cart.placed', true);
        this.getCart();
      } catch (e) {
        this.get('notify').error({ html: e.errors.join('<br>'), closeAfter: 5000 });
      }
    }),
    discountFreight: Ember.computed('cart.customer.noFreightAmount', 'availableExt', 'total', 'cartItems.@each.itemAlert', function () {

      console.log(this.get('cart.customer.noFreightAmount'));
      console.log(this.get('availableTotal'));

      if (this.get('availableTotal') >= this.get('cart.customer.noFreightAmount')) {
        return 'earned';
      } else {
        return Math.round(this.get('cart.customer.noFreightAmount') * 100 - this.get('availableTotal') * 100) / 100;
      }

      // let alert = false;
      // if (this.get('cart.cartItems.length') > 0) {
      //   this.get('cart.cartItems').forEach(function(ci){
      //     if (ci.get('itemAlert.id') && !ci.get('itemAlert.newArrivalAt')) {
      //       alert = true;
      //     }
      //   });
      // }

      // if (alert) {
      //   return 'alert';
      // }

      // if (this.get('cart.customer.discountFreightAmount')) {
      //   if (this.get('customer.chainId') === 1) {
      //     return;
      //   }
      //   if (this.get('total') >= this.get('cart.customer.discountFreightAmount')) {
      //     return 'earned';
      //   } else {
      //     return Math.round(this.get('cart.customer.discountFreightAmount') * 100 - this.get('total') * 100) / 100;
      //   }
      // }
    }),
    willCallTime: Ember.computed('cart.cartItems.length', 'model.shipment.shippingService.id', 'cart.cartItems.@each.sku', 'cart.cartItems.@each.inflated', function () {
      let time = (0, _moment.default)();
      if (this.get('model.approvedAt')) {
        time = (0, _moment.default)(this.get('model.approvedAt'));
      }
      let itemCount = Math.max(this.get('cart.cartItems.length'), 24);
      let itemTime = 600;
      if (this.get('cart.cartItems').filterBy('sku', 'RU-SH').length > 0) {
        itemCount = itemCount - 1;
        itemTime = 300;
      } else {
        if (time.get('hour') > 11) {
          time.add(5, 'hour');
        }
      }

      time.add(itemCount * itemTime + 600, 'seconds');

      if ((0, _moment.default)().get('hour') < 12 && time.get('hour') > 11) {
        time.add(1, 'hour');
      }
      if (time.get('hour') > 16) {
        time.add(15, 'hour');
      }

      let standard = (0, _moment.default)().startOf('day');
      standard.add(16, 'hour');

      if (this.get('cart.cartItems').filterBy('inflated', true).get('length') > 0) {
        time.add(1, 'day');
      } else if (itemCount < 100 && standard.get('hour') < 12) {
        if (time > standard) {
          return standard;
        }
      }

      if (time.get('day') === 6) {
        time.add(1, 'day');
      }
      if (time.get('day') === 0) {
        time.add(1, 'day');
      }

      if (time.get('hour') < 8) {
        time.add(8 - time.get('hour'), 'hour');
      }

      if (time.get('date') > (0, _moment.default)().add(8, 'hour').get('date')) {
        time = time.startOf('day').add(10, 'hour');
      }

      return time;
    }),
    presaleDates: Ember.computed('cart.cartItems.@each.presaleDate', function () {

      if (this.get('cart.cartItems.length') > 0) {
        return this.get('cart.cartItems').reduce(function (acc, val) {
          if (val.get('presaleDate') && acc.indexOf((0, _moment.default)(val.get('presaleDate')).format('MM/DD/YYYY')) === -1) {
            acc.push((0, _moment.default)(val.get('presaleDate')).format('MM/DD/YYYY'));
          }
          return acc;
        }, []);
      }
    }),
    cartSort: ['createdAt'],
    items: Ember.computed.sort('cart.cartItems', 'cartSort'),
    skus: Ember.computed.alias('cart.cartItems.length'),
    placed: Ember.computed.alias('cart.placed'),
    units: Ember.computed('cart.cartItems.@each.quantity', function () {
      if (this.get('cart')) {
        return this.get('cart.cartItems').reduce(function (acc, val) {
          acc += val.get('quantity') * 1;
          return acc;
        }, 0);
      }
    }),
    total: Ember.computed('cart.cartItems.@each.price', 'cart.cartItems.@each.quantity', function () {
      if (this.get('cart')) {
        let total = this.get('cart.cartItems').reduce(function (acc, val) {
          acc += val.get('extPrice');
          return acc;
        }, 0);
        if (this.get('shoppingCart.customer.chain.domains.length') == 0 && !this.get('cart.event.id')) {
          if (this.get('availableTotal') < 100) {
            total += 10;
          }
        }
        return Math.round(total * 100) / 100;
      }
    }),
    availableTotal: Ember.computed('cart.cartItems.@each.price', 'cart.cartItems.@each.quantity', function () {
      if (this.get('cart')) {
        let total = this.get('cart.cartItems').reduce(function (acc, val) {
          acc += val.get('availableExt');
          return acc;
        }, 0);
        return Math.round(total * 100) / 100;
      }
    }),
    discountMap: Ember.computed.mapBy('items', 'discount'),
    discounts: Ember.computed('discountMap', function () {
      let arr = [];
      for (let i of this.get('discountMap')) i && arr.push(i);

      return arr.uniqBy('code');
    }),
    init() {
      let _this = this;
      this._super(...arguments);
      _this.get('store').findAll('category-discount');
      _this.getCart();
    },
    categoryDiscounts: function () {
      return this.get('store').findAll('category-discount');
    },
    getCart: function () {
      if (this.get('session.isAuthenticated')) {
        let _this = this;
        this.get('store').queryRecord('cart', { type: 'current' }).then(cart => {
          _this.set('cart', cart);
          _this.set('paymentMethod', null);
          _this.set('placed', null);
          _this.set('willCallDate', null);
          _this.set('willCallHour', null);
          _this.set('cart.shippingService', cart.get('customer.shippingService'));
        });
      }
    },
    addItem: (0, _emberConcurrency.task)(function* (item, qty, alert) {
      if (this.get('cart.customer.disablePurchasing')) {
        this.get('notify').error({ html: `<div class="title">${item.get('title')}</div><div class="sku">${item.get('sku')} not added.</div><div class="quantity">Purchasing disabled for this account.</div>` });
        return;
      }

      if (item.search_id) {
        let search = item.search_id;
        item = yield this.get('store').findRecord('item', item.id);
        item.set('search', search);
      }

      let cartItem = null;

      this.get('cart.cartItems').filter(function (ci) {
        if (ci.get('item.id') == item.get('id')) {
          cartItem = ci;
        }
      });

      console.log(item);
      console.log(item.get('id'));

      console.log('Inflate Info');
      console.log(item.get('inflate'));
      console.log(this.get('customer.alwaysInflate') || item.get('itemType') === 3);

      cartItem = cartItem || this.get('store').createRecord('cart-item', {
        cart: this.get('cart'),
        item: item,
        itemId: item.get('id'),
        quantity: 0,
        inflated: item.get('inflate') && (this.get('customer.alwaysInflate') || item.get('itemType') === 3),
        createdAt: new Date(),
        searchId: item.get('search')
      });

      let notify = this.get('notify');

      let added = qty;
      let cartMessage = '';
      if (item.get('salesMultiple') && (this.get('customer.chain.requireInners') || item.get('forceSalesMultiple'))) {
        let inners = item.get('salesMultiple');

        if (this.get('customer.customerType') === 'grocery') {
          inners = item.get('grocerMinimum') || inners;
        }

        let newQty = cartItem.get('quantity') * 1 + added * 1;
        if (newQty % inners > 0) {
          added = Math.ceil(newQty / inners) * inners - cartItem.get('quantity');
          cartMessage = `<br>This item is sold in quantities of ${inners}.  We've adjusted your order to ${cartItem.get('quantity') * 1 + added}.`;
        }
      }

      cartItem.set('quantity', cartItem.get('quantity') * 1 + added * 1);
      if (alert) {
        cartItem.set('itemAlert', alert);
      }

      console.log(cartItem);

      cartItem.save().then(() => {
        notify.info({ html: `<div class="title">${item.get('title')}</div><div class="sku">${item.get('sku')}</div><div class="quantity">${added} added to cart.${cartMessage}</div>` });
      });
    }),
    add(item, qty = 1, alert) {
      this.get('addItem').perform(item, qty, alert);
    },
    setPromo(promo) {
      this.set('cart.promo', promo);
      this.get('cart').save();
      this.get('notify').info(`${promo.get('name')} - ${promo.get('code')} promotional pricing has been applied`);
    },
    remove(item) {
      item.destroyRecord();
    },
    empty() {
      this.get('items').clear();
    },
    place() {
      this.get('submitCart').perform();
    }
  });
});