define('web/components/customer-account', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    notify: Ember.inject.service(),
    passError: Ember.computed('password', 'confirm', function () {
      return this.get('password') && this.get('confirm') && this.get('password') !== this.get('confirm');
    }),
    actions: {
      submit: function () {
        let _this = this;
        this.get('ajax').request('/api/customers', { method: 'GET', data: {
            verify: true,
            number: this.get('account'),
            zip: this.get('zip')
          } }).then(response => {
          if (response.status === 'success') {
            _this.set('validation', response);
          }
        });
      },
      create: function () {
        let _this = this;
        if (this.get('passError')) {
          _this.get('notify').error('Passwords must match', { closeAfter: 8000 });
          return;
        }
        let user = this.get('store').createRecord('user', {
          validation: this.get('validation.key'),
          existingCustomer: this.get('validation.id'),
          email: this.get('email'),
          name: this.get('name'),
          username: this.get('username'),
          password: this.get('password'),
          passwordConfirmation: this.get('confirm')
        });

        user.save().then(function (model) {
          _this.get('notify').info('Account created', { closeAfter: 8000 });
          _this.set('accountCreated', true);
        }, function (error) {
          _this.set('username', null);
          _this.get('notify').error(error.errors[0], { closeAfter: 8000 });
        });
      }
    }
  });
});