define('web/components/related-items', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    uiSetup: function () {
      this.get('loadRelated').perform();
    }.on('didInsertElement').observes('item'),
    loadRelated: (0, _emberConcurrency.task)(function* () {
      this.set('related', null);
      try {
        let related = yield this.get('store').query('item-property-value', { related_to: this.get('item.id'), on: this.get('relateOn') });
        this.set('related', related);
      } catch (e) {}
    })
  });
});