define('web/routes/new-customer', ['exports', 'web/routes/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    model: function (params) {
      return this.get('store').createRecord('lead');
    }
  });
});