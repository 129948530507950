define('web/models/shipment', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    charge: _emberData.default.attr('number'),
    trackingNumber: _emberData.default.attr('string'),
    trackingUrl: Ember.computed('trackingNumber', function () {
      if (!this.get('trackingNumber') || this.get('trackingNumber.length') === 0) {
        return;
      }
      if (this.get('trackingNumber').slice(0, 2) === '1Z') {
        return `https://www.ups.com/track?loc=en_US&tracknum=${this.get('trackingNumber')}&requester=WT/trackdetails`;
      } else if (this.get('trackingNumber').slice(0, 3) == '940') {
        return `https://tools.usps.com/go/TrackConfirmAction?tLabels=${this.get('trackingNumber')}`;
      } else {
        return `https://www.fedex.com/apps/fedextrack/?tracknumbers=${this.get('trackingNumber')}`;
      }
    }),
    company: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    address1: _emberData.default.attr('string'),
    address2: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    zip: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    shippedAt: _emberData.default.attr('date'),
    blind: _emberData.default.attr('boolean'),
    comments: _emberData.default.attr('string'),
    shipVia: _emberData.default.attr('string'),
    packages: _emberData.default.attr('number'),
    credit: _emberData.default.attr('number'),
    address: Ember.computed('company', 'name', 'address1', 'address2', 'city', 'state', 'zip', 'country', function () {
      let address = [];
      address.pushObject(this.get('company'));
      if (this.get('name')) {
        address.pushObject(this.get('name'));
      }
      address.pushObject(this.get('address1'));
      if (this.get('address2')) {
        address.pushObject(this.get('address2'));
      }
      address.pushObject(`${this.get('city')}, ${this.get('state')} ${this.get('zip')}`);
      if (this.get('country.length') === 2 && this.get('country').toUpperCase() !== 'US') {
        address.pushObject(this.get('country'));
      }
      return address.join('<br>');
    })
  });
});