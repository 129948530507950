define('web/models/payment-token', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    token: _emberData.default.attr('string'),
    ccExpiresOn: _emberData.default.attr('date'),
    lastFour: _emberData.default.attr('string'),
    cardType: _emberData.default.attr('string'),
    avsResult: _emberData.default.attr('string'),
    cvvResult: _emberData.default.attr('string'),
    tokenType: _emberData.default.attr('string'),
    lastUsedAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    customer: _emberData.default.belongsTo('customer'),
    expMonth: _emberData.default.attr('string'),
    expYear: _emberData.default.attr('string'),
    nonce: _emberData.default.attr('string'),
    gateway: _emberData.default.attr('string'),
    //order: DS.belongsTo('order'),
    //orders: DS.hasMany('order'),
    carts: _emberData.default.hasMany('cart')
  });
});