define('web/models/user', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    username: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    domain: _emberData.default.attr('string', { readOnly: true }),
    lastLoginAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    customer: _emberData.default.belongsTo('customer'),
    contact: _emberData.default.belongsTo('contact'),
    password: _emberData.default.attr('string'),
    passwordConfirmation: _emberData.default.attr('string'),
    passwordReset: _emberData.default.attr('string'),
    validation: _emberData.default.attr('string'),
    existingCustomer: _emberData.default.attr('number'),
    chainManager: _emberData.default.attr('boolean'),
    isRep: _emberData.default.attr('boolean')

    // contact: DS.belongsTo('contact')
  });
});