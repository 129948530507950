define('web/components/customer-search', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  const DEBOUNCE_MS = 200;

  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    shoppingCart: Ember.inject.service(),
    results: {},
    router: Ember.inject.service(),
    placeholder: 'Search',
    matcher: function (opt, term) {
      let words = term.split(' ');
      for (var i = 0; i < words.length; i++) {
        if (opt.get('display').toUpperCase().indexOf(words[i].toUpperCase()) === -1) {
          return -1;
        }
      }
      return 1;
    },
    searchAsync: (0, _emberConcurrency.task)(function* (term) {
      if (term.length > 1) {
        if (this.get(`results.${term}`)) {
          return this.get(`results.${term}`);
        } else {
          yield (0, _emberConcurrency.timeout)(DEBOUNCE_MS);
          let url = `/api/customers?q=${encodeURIComponent(term)}`;
          let json = yield this.get('ajax').request(url);
          this.set(`results.${term}`, json);
          return json;
        }
      } else {}
    }).restartable(),
    actions: {
      select: function (result) {
        let _this = this;
        this.get('store').findRecord('customer', result.id).then(customer => {
          _this.set('currentUser.user.customer', customer);
          _this.get('currentUser.user').save().then(user => {
            _this.get('shoppingCart').getCart();
          });
        });
      }
    }
  });
});