define('web/models/customer-category-discount', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    categoryId: _emberData.default.attr('number'),
    discountCode: _emberData.default.attr('string'),
    discountType: _emberData.default.attr('string'),
    discountAmount: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    customer: _emberData.default.belongsTo('customer')
  });
});