define('web/models/item-alert', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    quantity: _emberData.default.attr('number'),
    quantityBackordered: _emberData.default.attr('number'),
    price: _emberData.default.attr('number'),
    backorderedAt: _emberData.default.attr('date'),
    notifiedAt: _emberData.default.attr('date'),
    notifiedById: _emberData.default.attr('number'),
    orderedAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    itemId: _emberData.default.attr('number'),
    item: _emberData.default.belongsTo('item'),
    customer: _emberData.default.belongsTo('customer'),
    newArrivalAt: _emberData.default.attr('date'),
    contact: _emberData.default.belongsTo('contact'),
    order: _emberData.default.belongsTo('order'),
    orderItem: _emberData.default.belongsTo('order-item'),
    displayQuantity: Ember.computed('quantity', 'quantityBackordered', function () {
      return this.get('quantityBackordered') || this.get('quantity');
    })
  });
});