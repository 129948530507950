define('web/controllers/forgot-password', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),
    notify: Ember.inject.service(),
    actions: {
      resetPassword: function () {
        let _this = this;
        this.get('store').createRecord('user', {
          username: this.get('username')
        }).save().then(() => {
          _this.set('sent', true);
          _this.get('notify').info('You will receive a password reset email if an account was found for this username or email address.');
          _this.set('username', null);
        });
      }
    }
  });
});