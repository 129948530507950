define('web/components/grid-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    shoppingCart: Ember.inject.service(),
    store: Ember.inject.service(),
    notify: Ember.inject.service(),
    customer: Ember.computed.alias('currentUser.user.customer'),
    prop65Restricted: Ember.computed('customer.prop65Restricted', 'item.prop65', function () {
      return !this.get('customer.prop65Permitted') && this.get('item.prop65');
    }),
    prop65Permitted: Ember.computed('customer.prop65Permitted', 'item.prop65', function () {
      return this.get('customer.prop65Permitted') || !this.get('item.prop65');
    }),
    salesMultiple: Ember.computed('customer.customerType', 'item.salesMultiple', 'item.grocerMinimum', function () {
      if (this.get('customer.customerType') === 'grocery') {
        return this.get('item.grocerMinimum') || this.get('item.salesMultiple');
      } else {
        return this.get('item.salesMultiple');
      }
    }),
    quantity: Ember.computed('customer.chain.requireInners', 'item.salesMultiple', 'item.forceSalesMultiple', function () {
      if (this.get('item.salesMultiple') && (this.get('customer.chain.requireInners') || this.get('item.forceSalesMultiple'))) {
        if (this.get('alert.quantity') && this.get('alert.quantity') > this.get('item.salesMultiple')) {
          return this.get('alert.quantity');
        }
        if (this.get('soi.displayQuantity') && this.get('soi.displayQuantity') > this.get('item.salesMultiple')) {
          return this.get('soi.displayQuantity');
        }

        if (this.get('currentUser.user.customer.customerType') === 'grocery') {
          return this.get('item.grocerMinimum') || this.get('item.salesMultiple');
        } else {
          return this.get('item.salesMultiple');
        }
      }
      return 1;
    }),
    actions: {
      addItem: function (item, alert, soi) {
        this.get('shoppingCart').add(item, this.get('quantity') * 1, alert);
      },
      notifyItem: function (item) {
        let id = null;
        try {
          id = item.get('id');
        } catch (err) {
          id = item.id;
        }

        let _this = this;
        let alert = this.get('store').createRecord('item-alert', {
          itemId: id,
          quantity: this.get('quantity'),
          customer: this.get('customer')
        });
        alert.save().then(ia => {
          _this.get('notify').info("We'll let you know when this is back in stock.");
        });
      }
    }

  });
});