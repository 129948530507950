define('web/models/promo', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    code: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    minimum: _emberData.default.attr('number'),
    promoMinimum: _emberData.default.attr('number'),
    percentDiscount: _emberData.default.attr('number'),
    freeShipping: _emberData.default.attr('boolean'),
    freeShippingMinimum: _emberData.default.attr('number'),
    startsAt: _emberData.default.attr('date'),
    endsAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    discountAmount: _emberData.default.attr('number'),
    discountSpecial: _emberData.default.attr('boolean'),
    promoItems: _emberData.default.hasMany('promo-item')
  });
});