define('web/controllers/checkout', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),
    shoppingCart: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    notify: Ember.inject.service(),
    placed: Ember.computed.alias('shoppingCart.placed'),
    serviceOptions: ['Ground', 'FedEx Ground', 'UPS Ground', 'Priority Mail', 'Will Call', 'Cheapest', 'Other'],
    paymentOptions: Ember.computed('currentUser.customer.terms', function () {
      return [this.get('currentUser.customer.terms'), 'Credit Card'];
    }),
    showTokens: Ember.computed('currentUser.customer.terms', 'shoppingCart.paymentMethod', function () {
      return this.get('shoppingCart.paymentMethod') && (this.get('shoppingCart.paymentMethod').indexOf('CASH') > -1 || this.get('shoppingCart.paymentMethod').indexOf('CREDIT CARD') > -1);
    }),
    tokenOptions: Ember.computed.alias('currentUser.customer.paymentTokens'),
    convertToken: (0, _emberConcurrency.task)(function* (token) {
      let _this = this;
      try {
        yield token.save();
      } catch (reason) {
        _this.get('notify').error('There was an error with your card.');
      }
      this.set('shoppingCart.cart.paymentToken', token);
    }),
    placeOrder: (0, _emberConcurrency.task)(function* () {
      yield this.get('shoppingCart').place();
    }),
    actions: {
      saveShipping: function () {
        this.get('shoppingCart.cart').save();
        this.set('billing', true);
      },
      saveBilling: function () {
        this.get('shoppingCart.cart').save();
        this.set('confirm', true);
      },
      reset: function () {
        this.set('confirm', false);
      },
      place: function () {
        this.get('placeOrder').perform();
      },
      addCard: function (nonce) {
        let token = this.get('store').createRecord('payment-token', {
          expMonth: nonce['exp_month'],
          expYear: nonce['exp_year'],
          lastFour: nonce['last_four'],
          token: nonce['token_value'],
          cardType: nonce['card_type']
        });

        this.get('convertToken').perform(token);
      }
    }
  });
});