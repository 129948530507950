define('web/models/lead', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    company: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    fax: _emberData.default.attr('string'),
    website: _emberData.default.attr('string'),
    skypeId: _emberData.default.attr('string'),
    source: _emberData.default.attr('string'),
    industry: _emberData.default.attr('string'),
    amazonSellerName: _emberData.default.attr('string'),
    address1: _emberData.default.attr('string'),
    address2: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    zip: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    emailOptOutAt: _emberData.default.attr('date'),
    applicationSentAt: _emberData.default.attr('date'),
    applicationReceivedAt: _emberData.default.attr('date'),
    taxExemptionReceivedAt: _emberData.default.attr('date'),
    taxExemptNumber: _emberData.default.attr('string'),
    applicationDenied: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    convertedAt: _emberData.default.attr('string'),
    createdById: _emberData.default.attr('number'),
    updatedById: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    zohoId: _emberData.default.attr('string'),
    user: _emberData.default.belongsTo('user'),
    recaptcha: _emberData.default.attr('string'),
    decor: Ember.computed('industry', function () {
      return this.get('industry') && this.get('industry').toUpperCase().indexOf('DECOR') > -1;
    })

  });
});