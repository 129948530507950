define('web/components/mega-menu', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      toggleMenu: function () {
        this.toggleProperty('showMenu');
      }
    }
  });
});