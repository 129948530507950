define('web/router', ['exports', 'web/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    metrics: Ember.inject.service(),

    didTransition() {
      this._super(...arguments);
      this._trackPage();
    },

    _trackPage() {
      Ember.run.scheduleOnce('afterRender', this, () => {
        const page = this.get('url');
        const title = this.getWithDefault('currentRouteName', 'unknown');

        Ember.get(this, 'metrics').trackPage({ page, title });
      });
    }
  });

  Router.map(function () {
    this.route('pages', function () {
      this.route('show', { path: '/:page_id' });
    });
    this.route('cart');
    this.route('cart-items');
    this.route('items', function () {
      this.route('show', { path: '/:item_id' });
      this.route('coming-soon');
      this.route('new-arrivals');
    });
    this.route('customers');
    this.route('checkout');
    this.route('forgot-password');
    this.route('reset-password', function () {
      this.route('show', { path: '/:user_id' });
    });
    this.route('orders', function () {
      this.route('history', { path: '/history' });
      this.route('show', { path: '/:order_id' });
      this.route('success', { path: '/:order_id/success' });
      this.route('pickup', { path: '/pickup/:token' });
    });

    this.route('search', function () {
      this.route('results', { path: '' });
      this.route('results', { path: '/:term' });
    });
    this.route('account');
    this.route('new-customer');
    this.route('existing-customer');

    this.route('articles', function () {
      this.route('show', { path: '/:article_id' });
    });
    this.route('jobs');
    this.route('alerts');
    this.route('pickup', function () {
      this.route('token', { path: '/:token' });
    });
    this.route('backorders');
  });

  exports.default = Router;
});