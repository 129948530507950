define('web/models/promo-item', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    sku: _emberData.default.attr('string'),
    skuMatch: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    discountAmount: _emberData.default.attr('number'),
    discountPercent: _emberData.default.attr('number'),
    promo: _emberData.default.belongsTo('promo'),
    item: _emberData.default.belongsTo('item'),
    promoVid: _emberData.default.attr('number'),
    discountCategory: _emberData.default.attr('string'),
    promoMinimum: _emberData.default.attr('number'),
    productIds: _emberData.default.attr(),
    display: Ember.computed('promo.percentDiscount', 'discountPercent', function () {
      return `$(this.get('discountPercent') || this.get('promo.percentDiscount')}%`;
    })
  });
});