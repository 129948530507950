define('web/components/show-item', ['exports', 'ember-keyboard-shortcuts/mixins/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_component.default, {
    currentUser: Ember.inject.service(),
    shoppingCart: Ember.inject.service(),
    session: Ember.inject.service(),
    metrics: Ember.inject.service(),
    notify: Ember.inject.service(),
    store: Ember.inject.service(),
    customer: Ember.computed.alias('currentUser.user.customer'),
    prop65Restricted: Ember.computed('customer.prop65Restricted', 'item.prop65', function () {
      console.log(this.get('item.prop65'));
    }),
    prop65Permitted: Ember.computed('customer.prop65Permitted', 'item.prop65', function () {
      console.log(this.get('item.prop65'));
      return this.get('customer.prop65Permitted') || this.get('item.prop65') * 1 < 1;
    }),

    quantity: Ember.computed('customer.chain.requireInners', 'item.salesMultiple', 'item.forceSalesMultiple', function () {
      if (this.get('item.salesMultiple') && (this.get('customer.chain.requireInners') || this.get('item.forceSalesMultiple'))) {
        if (this.get('customer.customerType') === 'grocery') {
          return this.get('item.grocerMininum') || this.get('item.salesMultiple');
        } else {
          return this.get('item.salesMultiple');
        }
      }
      return 1;
    }),
    item: Ember.computed.alias('model'),
    didInsertElement: function () {
      this.get('metrics').trackPage({
        page: `/items/${this.get('model.sku')}`,
        account: this.get('customer.number')
      });
    },
    discounts: Ember.computed('model.quantityDiscounts', function () {
      let qd = false;
      let item = this.get('item');
      this.get('item.discounts').forEach(function (disc) {
        if (disc.get('price') < item.get('price')) {
          qd = true;
        }
      });
      return qd;
    }),
    actions: {
      addItem: function (item) {
        this.get('shoppingCart').add(item, this.get('quantity') * 1);
      },
      notifyItem: function (item) {
        let _this = this;
        this.get('store').createRecord('item-alert', {
          itemId: item.get('id'),
          quantity: this.get('quantity'),
          customer: this.get('customer')
        }).save().then(ia => {
          _this.get('notify').info("We'll let you know when this is back in stock.");
        });
      },
      showImageModal: function (asset) {
        console.log(asset);
        this.set('modalAsset', asset);
        this.set('showingImageModal', true);
      },
      hideImageModal: function () {
        this.set('showingImageModal', false);
      }
    },
    keyboardShortcuts: {
      'esc': function () {
        this.sendAction('clearModalItem');
      }
    },
    price: Ember.computed('item.price', 'item.discounts.@each.ivLevel', function () {
      let itemPrice = this.get('item.price');
      if (!this.get('item.hasDiscounts')) {
        return this.get('item.price');
      } else {
        let _this = this;
        this.get('item.discounts').forEach(function (disc) {

          if (_this.get('quantity') >= disc.get('quantity') || _this.get('customer.ivLevel') * 1 > 1 && disc.get('level') < _this.get('customer.ivLevel') * 1) {

            itemPrice = disc.get('price');

            if (_this.get('quantity') >= disc.get('quantity')) {
              _this.set('priceMessage', `Buy ${disc.get('quantity')}+ @ ${itemPrice}`);
            } else {
              _this.set('priceMessage', `Level ${_this.get('order.customer.ivLevel')}`);
            }
          }
        });
      }

      return itemPrice;
    })
  });
});