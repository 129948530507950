define('web/components/item-price', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    shoppingCart: Ember.inject.service(),
    session: Ember.inject.service(),
    customer: Ember.computed.alias('currentUser.user.customer'),
    quantity: 1,
    item: Ember.computed.alias('model'),
    actions: {
      addItem: function (item) {
        this.get('shoppingCart').add(item, this.get('quantity') * 1);
      }
    },
    price: Ember.computed('customer.id', 'item.price', 'item.discounts.@each.ivLevel', function () {

      let itemPrice = this.get('item.price');

      let _this = this;
      if (this.get('item.discounts')) {
        this.get('item.discounts').forEach(function (disc) {

          if (disc.id && _this.get('quantity') >= disc.get('quantity')) {
            itemPrice = disc.get('price');
            if (_this.get('quantity') >= disc.get('quantity')) {
              _this.set('priceMessage', `Buy ${disc.get('quantity')}+ @ ${itemPrice}`);
            } else {
              _this.set('priceMessage', `Level ${_this.get('order.customer.ivLevel')}`);
            }
          }
        });
      }

      if (this.get('item.discountPrice') && this.get('item.discountPrice') < itemPrice) {
        itemPrice = this.get('item.discountPrice');
      }

      let customerItem = this.get('item.customerItems.firstObject');
      if (customerItem && customerItem.get('priceType') == 'force') {
        itemPrice = customerItem.get('price');
      } else if (customerItem && customerItem.get('priceType') == 'best' && customerItem.get('price') < itemPrice) {
        itemPrice = customerItem.get('price');
      }

      return itemPrice;
    })
  });
});