define('web/controllers/cart', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    preorder: Ember.computed(function () {
      return window.location.hostname.indexOf('preorder') > -1;
    }),
    alerts: Ember.computed.filter('model', function (alert) {
      return !alert.get('newArrivalAt');
    }),
    allArrivals: Ember.computed.filter('model', function (alert) {
      return !!alert.get('newArrivalAt');
    }),
    newArrivals: Ember.computed('allArrival.length', function () {
      return this.get('allArrivals').slice(0, 6);
    })
  });
});