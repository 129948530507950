define('web/components/checkout-flow', ['exports', 'ember-concurrency', 'moment', 'web/config/environment'], function (exports, _emberConcurrency, _moment, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    shoppingCart: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    notify: Ember.inject.service(),
    ajax: Ember.inject.service(),
    didInsertElement: function () {
      this.get('shoppingCart').getCart();
    },
    braintreeClientToken: _environment.default.braintreeClientToken,
    placed: Ember.computed.alias('shoppingCart.placed'),
    addressOptions: Ember.computed.alias('shoppingCart.cart.customer.shipTos'),
    serviceOptions: ['Ground', 'Priority Mail', 'Cheapest', 'Will Call'],
    prop65Options: ['customer_label', 'pre_label'],
    shippingOptions: Ember.computed(function () {
      return this.get('store').findAll('shipping-service');
    }),
    showShipDate: Ember.computed('shoppingCart.cart.comments', 'shoppingCart.cart.shipVia', 'shoppingCart.cart.mustShipBy', function () {

      return this.get('shoppingCart.cart.shipVia') && this.get('shoppingCart.cart.shipVia') !== 'Will Call' && (this.get('shoppingCart.cart.comments') && this.get('shoppingCart.cart.comments').toUpperCase().indexOf('MUST SHIP') > -1 || this.get('shoppingCart.cart.mustShipBy'));
    }),
    paymentOptions: Ember.computed('currentUser.customer.terms', function () {
      return [this.get('currentUser.customer.terms'), 'CREDIT CARD'];
    }),
    showTokens: Ember.computed('currentUser.customer.terms', 'shoppingCart.paymentMethod', function () {
      return this.get('shoppingCart.paymentMethod') && (this.get('shoppingCart.paymentMethod').indexOf('CASH') > -1 || this.get('shoppingCart.paymentMethod').indexOf('CREDIT CARD') > -1);
    }),
    tokenOptions: Ember.computed.alias('currentUser.customer.paymentTokens'),
    convertToken: (0, _emberConcurrency.task)(function* (token) {
      let _this = this;
      try {
        yield token.save();
      } catch (reason) {
        _this.get('notify').error('There was an error with your card.');
      }
      this.set('shoppingCart.cart.paymentToken', token);
    }),
    willCallHours: Ember.computed('shoppingCart.willCallDate', function () {
      let time = (0, _moment.default)(this.get('shoppingCart.willCallDate'));
      // console.log(time);
      // if (moment().isAfter(time.add(12,'hours'))) {
      //   console.log('Now is After');
      // } else {
      //   console.log('Now is Before');
      // }

      // if (time.add(13,'hours') > moment()) {
      //   console.log(moment);
      //   console.log(time.add(13,'hours'));
      //   console.log('after 13');
      // } else if (time.add(12,'hours') > moment()) {
      //   console.log('after 12');
      // } else if (time.add(11,'hours') > moment()) {
      //   console.log('after 11');
      // }


      let hour = (0, _moment.default)().hour();

      if (time > new Date()) {
        return [{ time: 'Before 10:00 am (will call closed)', disabled: true }, { time: '10:00 am - 11:00 am', hours: 10 }, { time: '11:00 am - 12:00 pm', hours: 11 }, { time: '12:00 pm - 1:00 pm (will call closed)', disabled: true }, { time: '1:00 pm - 2:00 pm', hours: 13 }, { time: '2:00 pm - 3:00 pm', hours: 14 }, { time: 'After 3:00 pm (will call closed)', disabled: true }];
      } else {
        let times = [];
        if (hour < 7) {
          times.push({ time: 'Before 10:00 am (will call closed)', disabled: true });
          times.push({ time: '10:00 am - 11:00 am', hours: 10 });
        }

        if (hour < 8) {
          times.push({ time: '11:00 am - 12:00 pm', hours: 11 });
          times.push({ time: '12:00 pm - 1:00 pm (will call closed)', disabled: true });
        }

        if (hour < 10) {
          times.push({ time: '1:00 pm - 2:00 pm', hours: 13 });
        }

        if (hour < 11) {
          times.push({ time: '2:00 pm - 3:00 pm', hours: 14 });
          times.push({ time: 'After 3:00 pm (will call closed)', disabled: true });
        }

        return times;
      }
    }),
    validateShipping: (0, _emberConcurrency.task)(function* () {
      yield this.get('shoppingCart.cart').save();
      if (this.get('shoppingCart.cart.shipVia') === 'Will Call') {
        this.set('billing', true);
      } else {
        let response = yield this.get('ajax').put(`/api/carts/${this.get('shoppingCart.cart.id')}/validate_address`);
        if (response.status == 'VALIDATED') {
          this.set('billing', true);
        } else if (response.status == 'OPTION') {
          this.set('addressValidation', response);
        } else if (response.status == 'ERROR') {
          this.set('billing', true);
        }
      }
      console.log(this.get('shoppingCart.cart.shipVia'));
    }),
    braintreeToken: (0, _emberConcurrency.task)(function* (nonce) {

      if (this.get('processingToken')) {
        return;
      }
      this.set('processingToken', true);

      let paymentToken = this.get('store').createRecord('payment-token', {
        customer: this.get('currentUser.customer'),
        nonce: nonce,
        gateway: 'braintree'
      });

      console.log(nonce);

      try {
        yield paymentToken.save();
        if (paymentToken.get('id')) {
          this.set('shoppingCart.cart.paymentToken', paymentToken);
        }
      } catch (e) {
        this.get('notify').error({ html: e.errors.join('<br>'), closeAfter: 10000 });
      }
    }),
    placeOrder: (0, _emberConcurrency.task)(function* () {
      yield this.get('shoppingCart.submitCart').perform();
    }),
    actions: {
      setAddress: function (shipTo) {
        this.set('shoppingCart.cart.shipTo', shipTo);
        this.set('shoppingCart.cart.company', shipTo.get('company'));
        this.set('shoppingCart.cart.name', shipTo.get('name'));
        this.set('shoppingCart.cart.address1', shipTo.get('address1'));
        this.set('shoppingCart.cart.address2', shipTo.get('address2'));
        this.set('shoppingCart.cart.city', shipTo.get('city'));
        this.set('shoppingCart.cart.state', shipTo.get('state'));
        this.set('shoppingCart.cart.zip', shipTo.get('zip'));
        this.set('shoppingCart.cart.country', shipTo.get('country'));
        console.log(shipTo);
      },
      skipValidation: function () {
        this.set('shoppingCart.cart.validationSkipped', true);
        this.set('addressValidation', null);
        this.set('billing', true);
      },
      acceptValidation: function () {
        this.set('shoppingCart.cart.address1', this.get('addressValidation.address1'));
        this.set('shoppingCart.cart.address2', this.get('addressValidation.address2'));
        this.set('shoppingCart.cart.city', this.get('addressValidation.city'));
        this.set('shoppingCart.cart.state', this.get('addressValidation.state'));
        this.set('shoppingCart.cart.zip', this.get('addressValidation.zip'));
        this.set('shoppingCart.cart.validatedAt', new Date());
        this.set('shoppingCart.cart.addressValidated', true);
        this.get('shoppingCart.cart').save();
        this.set('addressValidation', null);
        this.set('billing', true);
      },
      clearToken: function () {
        this.set('shoppingCart.cart.paymentToken', null);
      },
      braintreeToken: function (nonce) {
        this.get('braintreeToken').perform(nonce);
      },
      saveBilling: function () {
        this.set('shoppingCart.cart.terms', this.get('shoppingCart.paymentMethod'));
        this.get('shoppingCart.cart').save();
        this.set('confirm', true);
      },
      reset: function () {
        this.set('confirm', false);
      },
      addCard: function (nonce) {
        let token = this.get('store').createRecord('payment-token', {
          expMonth: nonce['exp_month'],
          expYear: nonce['exp_year'],
          lastFour: nonce['last_four'],
          token: nonce['token_value'],
          cardType: nonce['card_type']
        });

        this.get('convertToken').perform(token);
      },
      setShipVia: function (ship) {
        this.set('shoppingCart.cart.shipVia', ship);
        this.set('billing', false);
      },
      setWillCall: function (date) {
        this.set('shoppingCart.willCallDate', date);
        this.set('shoppingCart.willCallHour', null);
        this.set('shoppingCart.cart.mustShipBy', date);
      },
      setWillCallHour: function (hour) {
        let time = (0, _moment.default)(this.get('shoppingCart.willCallDate'));
        this.set('shoppingCart.willCallHour', hour);
        time = time.add(hour.hours, 'hours');
        this.set('shoppingCart.cart.mustShipBy', time.toDate());
      },
      clearPickup: function () {
        this.set('billing', null);
        this.set('shoppingCart.willCallDate', null);
        this.set('shoppingCart.willCallHour', null);
        this.set('shoppingCart.cart.mustShipBy', null);
      }
    }
  });
});