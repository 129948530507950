define('web/models/collection', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    sortOrder: _emberData.default.attr('string'),
    templateSuffix: _emberData.default.attr('string'),
    bodyHtml: _emberData.default.attr('string'),
    handle: _emberData.default.attr('string'),
    image: _emberData.default.attr('string'),
    position: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    page: _emberData.default.belongsTo('page'),
    collectionItems: _emberData.default.hasMany('collection-item'),
    sortedItems: Ember.computed('collectionItems.@each.id', 'sortOrder', function () {
      return this.get('collectionItems').filterBy('item.id').sortBy(this.get('sortOrder'));
    })
  });
});