define('web/components/edit-account', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    shoppingCart: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    notify: Ember.inject.service(),
    store: Ember.inject.service(),
    customer: Ember.computed.alias('currentUser.user.customer'),
    contact: Ember.computed.alias('currentUser.user.contact'),
    orderAlert: Ember.computed('contact.id', 'contact.alerts.@each.event', function () {
      return this.get('contact.alerts').filterBy('model', 'Order').filterBy('event', 'ready').filterBy('sms', true).get('firstObject');
    }),
    didInsertElement: function () {
      if (!this.get('contact.id')) {
        let contact = this.get('store').createRecord('contact');
        this.set('currentUser.user.contact', contact);
      }

      this.set('shipTo', this.get('customer.shipTos').filterBy('default', true).get('firstObject'));
    },
    canAlert: Ember.computed('contact.id', 'contact.cellPhone', function () {
      if (this.get('contact.id') && this.get('contact.cellPhone') && this.get('contact.cellPhone').match(/^\d{3}[-|\.]?\d{3}[-|\.]?\d{4}$/)) {
        return true;
      }
    }),
    actions: {
      newAddress: function () {
        let shipTo = this.get('store').createRecord('ship-to', { customer: this.get('customer') });
        this.set('shipTo', shipTo);
      },
      save: function () {
        let notify = this.get('notify');
        this.get('currentUser.user').save().then(() => {
          notify.info('Account updated.');
        });
      },
      setAsDefault: function (shipTo) {
        console.log(shipTo);
        this.set('customer.defaultShipToId', shipTo.get('id'));
        this.send('saveCustomer');
      },
      toggleAlert: function () {
        let alert = this.get('contact.alerts').filterBy('model', 'Order').filterBy('event', 'ready').get('firstObject');
        if (alert) {
          alert.toggleProperty('sms');
          alert.save();
        } else {
          this.get('store').createRecord('alert', {
            model: 'Order',
            event: 'ready',
            sms: true,
            contact: this.get('contact')
          }).save();
        }
      },
      saveCustomer: function () {
        let notify = this.get('notify');
        if (this.get('customer.isFulfilled')) {
          this.get('customer').then(customer => {
            customer.save().then(() => {
              notify.info('Customer Info updated.');
            });
          });
        } else {
          this.get('customer').save().then(() => {
            notify.info('Customer Info updated.');
          });
        }
      },
      saveContact: function () {
        let notify = this.get('notify');
        console.log(this.get('contact.cellPhone'));
        if (this.get('contact.isFulfilled')) {
          this.get('contact').then(contact => {
            contact.save().then(() => {
              notify.info('Contact Info updated.');
            });
          });
        } else {
          this.get('contact').save().then(() => {
            notify.info('Contact Info updated.');
          });
        }
      },
      saveShipTo: function () {
        let notify = this.get('notify');
        this.get('shipTo').save().then(() => {
          notify.info('Address updated.');
        });
      },
      destroyShipTo: function () {
        let _this = this;
        let notify = this.get('notify');
        this.get('shipTo').destroyRecord().then(() => {
          notify.warning('Address deleted.');
          _this.set('shipTo', null);
        });
      }
    }
  });
});