define('web/models/cart-item', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    currentUser: Ember.inject.service(),
    customer: Ember.computed.alias('currentUser.user.customer'),
    quantity: _emberData.default.attr('number'),
    canInflate: Ember.computed.alias('item.inflate'),
    inflated: _emberData.default.attr('boolean'),
    searchId: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    cart: _emberData.default.belongsTo('cart'),
    itemAlert: _emberData.default.belongsTo('item-alert'),
    item: _emberData.default.belongsTo('item'),
    itemId: _emberData.default.attr('number'),
    basePrice: Ember.computed('item.price', 'inflated', function () {
      if (this.get('inflated')) {
        return this.get('item.inflatedPrice');
      }
      return this.get('item.price');
    }),
    event: Ember.computed.alias('item.event'),
    prop65: Ember.computed.alias('item.prop65'),
    salesMultiple: Ember.computed('customer.customerType', 'item.salesMultiple', 'item.grocerMinimum', function () {
      if (this.get('customer.customerType') === 'grocery') {
        return this.get('item.grocerMinimum') || this.get('item.salesMultiple');
      } else {
        return this.get('item.salesMultiple');
      }
    }),
    presaleDate: Ember.computed.alias('item.presaleEndsAt'),
    promoEligible: Ember.computed('inflated', 'cart.promoSubTotal', 'cart.promo', 'cart.promo.promoItems.@each.sku', 'overridePrice', 'cart.special', 'item.productGroup.cost', 'item.discounts', 'cart.customer.customerType', 'cart.terms', 'basePrice', 'discountCategory', 'categoryQuantity', 'quantity', function () {
      let item = this.get('item');
      let eligible = false;
      if (this.get('order.promo') && this.get('order.promo.promoItems.length') > 0) {
        this.get('order.promo.promoItems').forEach(function (pi) {
          if (pi.get('promoVid') && pi.get('promoVid') === item.get('promoVid') || !pi.get('promoVid') && (pi.get('item') && pi.get('item') == item || pi.get('skuMatch') === 'any' && pi.get('sku').split(' ').indexOf(item.get('sku')) > -1 || pi.get('skuMatch') === 'equals' && pi.get('sku') === item.get('sku') || pi.get('skuMatch') === 'starts_with' && item.get('sku').slice(0, pi.get('sku.length')) == pi.get('sku') || pi.get('discountCategory') && pi.get('discountCategory.length') > 0 && pi.get('discountCategory') == item.get('discountCategory') || pi.get('productIds').indexOf(item.get('productType.id') * 1) > -1)) {
            eligible = true;
          }
        });
      }
      console.log(eligible);
      return eligible;
    }),
    price: Ember.computed('cart.promo', 'cart.promo.promoItems.@each.sku', 'cart.cartItems.@each.quantity', 'inflated', 'customer.id', 'quantity', 'item.price', 'item.discounts.@each.level', function () {

      let itemPrice = this.get('basePrice');
      if (this.get('inflated')) {
        itemPrice = this.get('item.inflatedPrice');
      }

      if (this.get('item.salePrice') && this.get('item.salePrice') < itemPrice) {
        itemPrice = this.get('item.salePrice');
      }

      if (this.get('item.groupPrice')) {
        return this.get('item.groupPrice');
      }

      let _this = this;
      if (this.get('cart.promo') && this.get('cart.promo.promoItems.length') > 0 && (!this.get('cart.promo.minimum') || this.get('cart.baseSubTotal') >= this.get('cart.promo.minimum')) && (!this.get('cart.promo.promoMinimum') || this.get('cart.promoSubTotal') >= this.get('cart.promo.promoMinimum'))) {

        let discount = false;
        let item = this.get('item');

        if (this.get('cart.promo.discountSpecial') && this.get('item.specialPrice')) {
          itemPrice = Math.min(this.get('item.specialPrice') || itemPrice, itemPrice);
          if (this.get('item.specialPrice')) {
            this.set('priceMessage', this.get('cart.promo.code'));
          }
        } else {

          if (this.get('cart.promo.casePricing')) {
            if (this.get('item.discounts')) {
              this.get('item.discounts').forEach(function (disc) {
                itemPrice = Math.min(itemPrice, disc.get('price'));
                _this.set('priceMessage', `Case Pricing`);
              });
            }
          }

          this.get('cart.promo.promoItems').forEach(function (pi) {
            if ((!pi.get('promoMinimum') || _this.get('cart.promoSubTotal') >= pi.get('promoMinimum')) && (pi.get('promoVid') && pi.get('promoVid') === item.get('promoVid') || pi.get('item') && pi.get('item') == item || pi.get('skuMatch') === 'any' && pi.get('sku').split(' ').indexOf(item.get('sku')) > -1 || pi.get('skuMatch') === 'equals' && pi.get('sku') === item.get('sku') || pi.get('skuMatch') === 'starts_with' && item.get('sku').slice(0, pi.get('sku.length')) == pi.get('sku') || pi.get('closeout') && item.get('closeoutAt') || pi.get('discountCategory') && pi.get('discountCategory.length') > 0 && pi.get('discountCategory') == item.get('discountCategory') || pi.get('productIds').indexOf(item.get('productTypeId')) > -1)) {

              if (!_this.get('vendorMatched')) {
                discount = Math.max(pi.get('discountPercent') || _this.get('cart.promo.percentDiscount'), discount || 0);
                if (pi.get('discountPercent') === 0) {
                  discount = 0;
                }
              }

              if (pi.get('promoVid') == _this.get('item.promoVid')) {
                _this.set('vendorMatched', true);
                discount = pi.get('discountPercent');
              }
            }
          });

          if (discount) {
            _this.set('priceMessage', `${_this.get('cart.promo.code')} ${discount}%`);
            itemPrice = Math.round(Math.min(itemPrice - Math.round(itemPrice * discount) / 100) * 100) / 100;
          }
        }
      }

      let customerItem = this.get('item.customerItems.firstObject');
      if (customerItem && customerItem.get('priceType') == 'force') {
        itemPrice = customerItem.get('price');
      } else if (customerItem && customerItem.get('priceType') == 'best' && customerItem.get('price') < itemPrice) {
        itemPrice = customerItem.get('price');
      }

      if (this.get('item.discounts')) {
        this.get('item.discounts').forEach(function (disc) {

          if (_this.get('quantity') >= disc.get('quantity') || _this.get('customer.ivLevel') * 1 > 1 && disc.get('level') < _this.get('customer.ivLevel') * 1) {

            itemPrice = Math.min(itemPrice, disc.get('price'));
            if (_this.get('quantity') >= disc.get('quantity')) {
              _this.set('priceMessage', `Buy ${disc.get('quantity')}+ @ ${itemPrice}`);
            } else if (_this.get('order.customer.ivLevel')) {
              _this.set('priceMessage', `Level ${_this.get('order.customer.ivLevel')}`);
            }
          }
        });
      }

      if (['wholsale', 'preferred', 'premium', 'grocery', 'partner'].indexOf(this.get('customer.customerType')) > -1) {
        return itemPrice;
      }

      // if (this.get('latexPrice') && this.get('latexPrice') < itemPrice) {
      //   itemPrice = this.get('latexPrice');
      // }

      console.log(this.get('item.sku'));
      console.log(this.get('categoryPrice'));

      if (this.get('categoryPrice') && this.get('categoryPrice') < itemPrice) {
        itemPrice = this.get('categoryPrice');
      }

      if (!this.get('inflated') && this.get('item.discountPrice') && this.get('item.discountPrice') < itemPrice) {
        itemPrice = this.get('item.discountPrice');
      }

      return itemPrice;
    }),

    discountCategory: Ember.computed.alias('item.discountCategory'),
    categoryValue: Ember.computed('quantity', 'cart.cartItems.@each.discountCategory', 'cart.cartItems.@each.price', 'cart.cartItems.@each.quantity', function () {
      let _this = this;
      let qty = this.get('cart.cartItems').reduce(function (acc, value) {
        if (value.get('discountCategory') === _this.get('discountCategory')) {
          acc += value.get('basePrice') * value.get('quantity');
        }
        return acc;
      }, 0);
      return Math.round(100 * qty) / 100;
    }),
    latexValue: Ember.computed('categoryValue', 'discountCategory', function () {
      // if (this.get('discountCategory') === 'L') {
      //   return this.get('categoryValue');
      // }
    }),
    categoryQuantity: Ember.computed('quantity', 'cart.cartItems.@each.discountCategory', 'cart.cartItems.@each.quantity', function () {
      let _this = this;
      // if (this.get('discountCategory') === 'L') {
      //   if (this.get('cart.user.customer.company') && this.get('cart.user.customer.company').indexOf("SMITH'S") > -1) {
      //     return '0%';
      //   } else if (this.get('categoryValue') >= 1000) {
      //     return '10%';
      //   } else if (this.get('categoryValue') >= 500) {
      //     return '5%';
      //   } else {
      //     return '0%';
      //   }
      // }
      let qty = this.get('cart.cartItems').reduce(function (acc, value) {
        if (value.get('discountCategory') === _this.get('discountCategory')) {
          if (['LF-0118', 'LF-0119', 'LF-SHAP'].indexOf(value.get('sku')) < 0) {
            acc += value.get('quantity') * 1;
          }
        }
        return acc;
      }, 0);
      return qty;
    }),
    categoryDiscounts: Ember.computed('discountCategory', function () {
      return this.store.peekAll('categoryDiscount').filterBy('discountCode', this.get('discountCategory'));
    }),
    categoryDiscount: Ember.computed('quantity', 'categoryDiscounts', 'categoryQuantity', function () {
      let _this = this;
      return this.get('categoryDiscounts').filter(function (item) {
        return item.get('minQuantity') <= _this.get('categoryQuantity') && item.get('maxQuantity') >= _this.get('categoryQuantity');
      }).get('firstObject');
    }),
    nextDiscount: Ember.computed('quantity', 'categoryDiscount', 'categoryDiscounts', 'categoryQuantity', function () {
      let _this = this;
      return this.get('categoryDiscounts').filter(function (item) {
        return item.get('discountLevel') === (_this.get('categoryDiscount.discountLevel') || 0) + 1;
      }).get('firstObject');
    }),
    nextDiscountQty: Ember.computed.alias('nextDiscount.minQuantity'),
    customerCategoryDiscount: Ember.computed('id', 'item.id', 'quantity', 'cart.user.customer.id', 'cart.user.customer.categoryDiscounts.@each.discountCode', function () {
      return this.get('cart.user.customer.categoryDiscounts').filterBy('discountCode', this.get('discountCategory')).get('firstObject');
    }),
    categoryPrice: Ember.computed('inflated', 'quantity', 'categoryQuantity', 'customerCategoryDiscount.discountCode', 'discountCategory', 'cart.user.customer.club', function () {
      this.set('priceMessage', '');
      let _this = this;
      let discount = this.get('customerCategoryDiscount');
      console.log('category discount');
      console.log(discount);
      if (discount && !this.get('inflated')) {
        let basePrice = _this.get('basePrice');
        if (discount.get('discountType') === 'discount' && !this.get('cart.user.customer.club')) {
          _this.set('priceMessage', '');
          return Math.round(Math.round(basePrice * (100 - discount.get('discountAmount')) * 100) / 100) / 100;
        } else if (discount.get('discountType') === 'cost_plus') {
          _this.set('priceMessage', 'Cost+');
          // console.log(_this.get('item.discountPrice'));
          return _this.get('item.discountPrice'); //Math.round(100 * (_this.get('baseCost') + (_this.get('baseCost') * discount.get('discountAmount') / 100))) / 100;
        } else if (discount.get('discountType') === 'max_discount' && !this.get('cart.user.customer.club')) {
          _this.set('priceMessage', 'Max');
          let discountAmount = 0;
          _this.get('categoryDiscounts').forEach(function (discount) {
            discountAmount = Math.max(discountAmount, discount.get('discountAmount'));
          });

          console.log(discountAmount);
          console.log(basePrice);
          console.log(Math.round(basePrice * (100 - discountAmount)) / 100);
          return Math.round(basePrice * (100 - discountAmount)) / 100;
        }
      } else if (this.get('categoryDiscount') && !this.get('cart.user.customer.club')) {
        this.set('priceMessage', `Disc ${this.get('categoryDiscount.discountCode')}`);
        return this.get('basePrice') - Math.round(this.get('basePrice') * this.get('categoryDiscount.discountAmount')) / 100;
      }
    }),
    latexPrice: Ember.computed('discountCategory', 'categoryValue', 'basePrice', function () {
      // if (this.get('discountCategory') === 'L' && (!this.get('cart.user.customer.company') || this.get('cart.user.customer.company').indexOf("SMITH'S") === -1)) {
      //   if (this.get('categoryValue') >= 1000) {
      //     return this.get('basePrice') - Math.round(this.get('basePrice') * 10)/100;
      //   } else if (this.get('categoryValue') >= 500) {
      //     return this.get('basePrice') - Math.round(this.get('basePrice') * 5)/100;
      //   }
      // }  
    }),
    discount: Ember.computed('categoryDiscount', 'discountCategory', 'nextDiscount', 'nextDiscountQty', 'latexPrice', 'basePrice', 'item.discountPrice', function () {
      if (this.get('item.discountPrice')) {
        return;
      }
      // if (this.get('discountCategory') === 'L') {
      //   if (this.get('latexPrice') < this.get('item.discountPrice') || this.get('basePrice')) {
      //     if (this.get('categoryValue') >= 1000) {
      //       return {code: this.get('discountCategory'), current: '$1000 / 10%', next: 'Max'};
      //     } else if (this.get('categoryValue') >= 500) {
      //       return {code: this.get('discountCategory'), current: '$500 / 5%', next: '$1000 / 10%', needed: (1000 - this.get('categoryValue'))};
      //     } else {
      //       return {code: this.get('discountCategory'), current: '', next: '$500 / 5%', needed: (500 - this.get('categoryValue'))};
      //     }
      //   }
      // } else 
      if (this.get('categoryDiscounts.length') > 0 && this.get('categoryDiscounts.firstObject.minQuantity') > 0) {
        return { code: this.get('discountCategory'), current: this.get('categoryDiscount.minQuantity') ? `${this.get('categoryDiscount.minQuantity')} / ${this.get('categoryDiscount.discountAmount') * 1}%` : '', next: this.get('nextDiscountQty') ? `${this.get('nextDiscountQty')} / ${this.get('nextDiscount.discountAmount') * 1}%` : 'Max', needed: this.get('nextDiscountQty') ? this.get('nextDiscountQty') - this.get('categoryQuantity') : 0 };
      }
    }),

    backordered: Ember.computed('quantity', 'item.available', function () {
      if (this.get('item.available') < this.get('quantity')) {
        return this.get('quantity') - this.get('item.available');
      }
    }),
    extPrice: Ember.computed('cart.cartItems.@each.quantity', 'inflated', 'price', 'quantity', function () {
      return Math.round(this.get('price') * this.get('quantity') * 100) / 100;
    }),
    availableExt: Ember.computed('cart.cartItems.@each.quantity', 'inflated', 'price', 'quantity', 'item.available', function () {
      if (this.get('item.specialOrder')) {
        return this.get('extPrice');
      } else {

        return Math.round(this.get('price') * Math.min(this.get('quantity'), this.get('item.available')) * 100) / 100;
      }
    })
  });
});