define('web/controllers/jobs', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    minDate: Ember.computed(function () {
      return new Date();
    }),
    actions: {
      setStartDate: function (date) {
        this.set('model.startDate', date);
      },
      submit: function () {

        if (!this.get('model.name') || !this.get('model.email') && !this.get('model.phone')) {
          this.get('notify').error('Name, email, and phone are required.');
          return;
        }

        let description = '';

        description += "\nHow did you hear about us?\n" + this.get('model.heardFrom');
        description += "\nDesired Pay:\n" + this.get('model.hourlyPay') + " per hour";
        description += "\nHigh School Name and Location/Graduated:\n" + this.get('model.highSchool');
        description += "\nCollege Name and Location/Graduated:\n" + this.get('model.college');
        description += "\nTrade School Name and Location/Graduated:\n" + this.get('model.tradeSchool');
        description += "\nOther Education:\n" + this.get('model.education');
        description += "\nAvailable Start Date:\n" + this.get('model.startDate');
        description += "\nU.S. Work Eligible:\n" + this.get('model.eligible');
        description += "\nDesired Schedule/Hours:\n" + this.get('model.schedule');

        this.set('model.description', description);

        let _this = this;

        this.get('model').save().then(() => {
          _this.get('notify').info('Your information has been submitted');
          _this.set('submitted', true);
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        });
      }
    }
  });
});