define('web/controllers/pages/show', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    queryParams: ['a', 'b', 'pt', 't', 's', 'sh', 'c', 'cf', 'o'],
    params: Ember.computed('a', 'b', 'pt', 't', 's', 'sh', 'c', 'cf', 'o', function () {
      return { es: this.get('t'), refinements: {
          product_type: this.get('pt'),
          brand: this.get('b'),
          size: this.get('s'),
          shape: this.get('sh'),
          color: this.get('c'),
          color_family: this.get('cf'),
          occasion: this.get('o'),
          availability: this.get('a')
        }

      };
    }),
    results: Ember.computed('term', 'b', 'pt', 's', 'sh', 'c', 'cf', 'o', 'a', function () {
      if (this.get('term') || this.get('b') || this.get('pt') || this.get('s') || this.get('sh') || this.get('c') || this.get('cf') || this.get('o') || this.get('a')) {
        return true;
      }
    }),
    filteredItems: Ember.computed('model', 'product_type', 'b', 's', 'sh', 'c', 'cf', function () {
      let product_type = this.get('product_type');
      let brand = this.get('b');
      this.get('getResults').perform();
      console.log(product_type);
      console.log(brand);
    }),
    getResults: (0, _emberConcurrency.task)(function* () {
      console.log(this.get('params'));
      let es = yield this.get('ajax').request('api/items', { data: this.get('params') });
      this.set('es', es);
    })
  });
});