define('web/components/search-results', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    selections: [],

    results: Ember.computed('params', function () {

      console.log(this.get('model.length'));
      console.log(this.get('model'));
    }),
    didInsertElement: function () {
      let _this = this;
      if (!this.get('model')) {
        console.log('no model');
        this.get('ajax').request('api/items', { data: { es: this.get('lp.es'), handoff: this.get('lp'), caller: this.get('caller'), params: this.get('params') } }).then(res => {
          _this.set('model', res);
        });
      } else {
        console.log('model');
      }
      console.log(this.get('lp'));
      console.log(this.get('params'));
      console.log('inserted');
      if (this.get('model.data')) {
        this.get('loadBackup').perform();
      }
    },
    loadBackup: (0, _emberConcurrency.task)(function* () {
      let _this = this;
      this.get('store').pushPayload(this.get('model'));
      let ids = this.get('model.data').reduce(function (acc, val) {
        acc.push(_this.get('store').peekRecord('item', val.id));
        return acc;
      }, []);

      this.set('model', ids);
    }),
    facets: Ember.computed('newFacets', 'model.facets', function () {
      return this.get('newFacets') || this.get('model.facets');
    }),
    updateResults: (0, _emberConcurrency.task)(function* () {

      let refinements = {};
      this.get('model.facets').forEach(function (facet) {

        console.log('search facet');
        console.log(facet);

        facet.buckets.forEach(function (bk) {
          if (bk.checked) {
            if (!refinements[facet.name]) {
              refinements[facet.name] = [];
            }
            refinements[facet.name].pushObject(bk.key);
          }
        });
      });

      console.log(this.get('selections'));

      let refine = yield this.get('ajax').request('api/items', { data: { es: this.get('model.terms'), handoff: this.get('lp'), params: this.get('params'), refinements: refinements, selections: this.get('selections') } });
      // console.log(refine);
      this.set('refinedGroups', refine.groups);
      this.set('refinements', refine.results);
      console.log(this.get('refinements'));

      console.log(refine.facets);

      this.set('newFacets', refine.facets);
      console.log(this.get('newFacets'));
    }),
    actions: {
      toggleFacet: function (facet) {

        facet.toggleProperty('showAll');
      },
      doRefine: function (facet, newFacet, bk) {

        console.log('do refine');

        // console.log(facet);

        // console.log(newFacet);

        // console.log(bk);


        let _this = this;

        setTimeout(function () {

          if (bk.checked) {
            _this.get('selections').push(`${facet.name}.${bk.key}`);
          } else {
            _this.get('selections').pop(`${facet.name}.${bk.key}`);
          }

          if (facet.name !== _this.get('lastFacet.name')) {
            if (_this.get('facets')) {
              _this.set('previousFacets', _this.get('facets'));
            } else {
              _this.set('previousFacets', _this.get('model.facets'));
            }
          }

          _this.get('previousFacets').forEach(function (fc) {
            if (fc.name == facet.name) {
              _this.set('lastFacet', fc);
            }
          });

          _this.get('updateResults').perform();
        }, 5);
      },
      refineSearch: function () {},
      toggleShow: function (facet) {
        console.log(facet);
        facet.set('showAll', !facet.showAll);
      }
    }

  });
});