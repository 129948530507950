define('web/models/order-item', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    item: _emberData.default.belongsTo('item'),
    uvId: _emberData.default.attr('number'),
    title: _emberData.default.attr('string'),
    quantity: _emberData.default.attr('number'),
    unit: _emberData.default.attr('string'),
    quantityBackordered: _emberData.default.attr('number'),
    quantityShipped: Ember.computed('quantity', 'quantityBackordered', function () {
      return this.get('quantity') - this.get('quantityBackordered') || 0;
    }),
    price: _emberData.default.attr('number'),
    extPrice: _emberData.default.attr('number'),
    comments: _emberData.default.attr('string'),
    position: _emberData.default.attr('number')
  });
});