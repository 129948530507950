define('web/controllers/orders/pickup', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    geolocation: Ember.inject.service(),
    ajax: Ember.inject.service(),
    locChanged: Ember.observer('geolocation.currentLocation', function () {
      let _this = this;
      this.get('geolocation').getLocation().then(geoObject => {
        _this.get('ajax').request(`/api/orders/pickup?token=${_this.get('model.token')}&lat=${geoObject.coords.latitude}&lon=${geoObject.coords.longitude}`);
      });
    }),
    actions: {
      onMyWay: function () {
        this.set('onmyway', true);
        this.get('ajax').request(`/api/orders/pickup?token=${this.get('model.token')}&onmyway=true`);
        this.get('geolocation').trackLocation().then(function (geoObject) {
          //do nothing
        });
      },
      imHere: function () {
        this.set('imhere', true);
        this.get('ajax').request(`/api/orders/pickup?token=${this.get('model.token')}&imhere=true`);
      }

    }
  });
});