define('web/models/item', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    sku: _emberData.default.attr('string'),
    upc: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    uom: _emberData.default.attr('string'),
    onHand: Ember.computed.alias('available'),
    available: _emberData.default.attr('number'),
    length: _emberData.default.attr('number'),
    width: _emberData.default.attr('number'),
    height: _emberData.default.attr('number'),
    weight: _emberData.default.attr('number'),
    caseLength: _emberData.default.attr('number'),
    caseHeight: _emberData.default.attr('number'),
    caseWeight: _emberData.default.attr('number'),
    caseQuantity: _emberData.default.attr('number'),
    innerLength: _emberData.default.attr('number'),
    innerWidth: _emberData.default.attr('number'),
    innerHeight: _emberData.default.attr('number'),
    innerQuantity: _emberData.default.attr('number'),
    salesMultiple: _emberData.default.attr('number'),
    forceSalesMultiple: _emberData.default.attr('boolean'),
    grocerMinimum: _emberData.default.attr('number'),
    category: _emberData.default.attr('string'),
    quantityCommitted: _emberData.default.attr('number'),
    itemPropertyValues: _emberData.default.hasMany('item-property-value'),
    assets: _emberData.default.attr(),
    comments: _emberData.default.attr('string'),
    itemType: _emberData.default.attr('number', { readOnly: true }),
    isNew: _emberData.default.attr('boolean', { readOnly: true }),
    disc: _emberData.default.attr('boolean', { readOnly: true }),
    mapRequired: _emberData.default.attr('boolean', { readOnly: true }),
    seasonStockedOutAt: _emberData.default.attr('date'),
    prop65: _emberData.default.attr('number'),
    presaleEndsAt: _emberData.default.attr('date'),
    presale: Ember.computed('presaleEndsAt', function () {
      return this.get('available') <= 0 && this.get('presaleEndsAt') && this.get('presaleEndsAt') > new Date();
    }),
    expectedOn: _emberData.default.attr('date'),
    discountCategory: _emberData.default.attr('string'),
    msrp: _emberData.default.attr('number'),
    inflate: _emberData.default.attr('boolean'),
    inflatedPrice: _emberData.default.attr('number'),
    description: _emberData.default.attr('string'),
    price: _emberData.default.attr('number', { readOnly: true }),
    mapPrice: _emberData.default.attr('number'),
    groupPrice: _emberData.default.attr('number', { readOnly: true }),
    productTypeId: _emberData.default.attr('number'),
    packageCount: _emberData.default.attr('number'),
    unitQuantity: _emberData.default.attr('number'),
    perUnit: _emberData.default.attr('number'),
    specialPrice: _emberData.default.attr('number'),
    salePrice: _emberData.default.attr('number'),
    discountPrice: _emberData.default.attr('number', { readOnly: true }),
    quantityDiscounts: _emberData.default.hasMany('quantity-discount'),
    collectionItems: _emberData.default.hasMany('collection-item'),
    promoVid: _emberData.default.attr('number'),
    articleItem: _emberData.default.belongsTo('article-item'),
    event: _emberData.default.belongsTo('event'),
    createdAt: _emberData.default.attr('date'),
    customerItems: _emberData.default.hasMany('customer-item', { inverse: 'item' }),
    discounts: Ember.computed.sort('quantityDiscounts', 'sortDiscounts'),
    stockSort: Ember.computed('available', function () {
      return this.get('available') > 0 ? 0 : 1;
    }),
    sortDiscounts: ['level'],
    hasDiscounts: Ember.computed('discounts', function () {
      return this.get('discounts.length') > 0;
    }),
    fullImage: _emberData.default.attr('boolean'),
    specialOrder: _emberData.default.attr('boolean'),
    stockItem: _emberData.default.attr('boolean', { readOnly: true }),
    imageBase: '//dkewhs09r9f5z.cloudfront.net',
    imageUrl: Ember.computed('sku', function () {
      let sku = this.get('sku');
      if (this.get('fullImage')) {
        return `${this.get('imageBase')}/products/50/${sku}.jpg`;
      } else if (sku) {
        return `${this.get('imageBase')}/assets/legacy/${sku}.gif`;
      }
    }),
    medImageUrl: Ember.computed('sku', function () {
      let sku = this.get('sku');
      if (this.get('fullImage')) {
        return `${this.get('imageBase')}/products/200/${sku}.jpg`;
      } else if (sku) {
        return `${this.get('imageBase')}/assets/legacy/${sku}.gif`;
      }
    }),
    largeImageUrl: Ember.computed('sku', function () {
      let sku = this.get('sku');
      if (this.get('fullImage')) {
        return `${this.get('imageBase')}/products/300/${sku}.jpg`;
      } else if (sku) {
        return `${this.get('imageBase')}/assets/legacy/${sku}.gif`;
      }
    }),
    maxImageUrl: Ember.computed('sku', function () {
      let sku = this.get('sku');
      if (this.get('fullImage')) {
        return `${this.get('imageBase')}/products/x/${sku}.jpg`;
      } else if (sku) {
        return `${this.get('imageBase')}/assets/legacy/${sku}.gif`;
      }
    }),
    sizeName: Ember.computed('itemPropertyValues.@each.id', function () {
      return this.get('itemPropertyValues').filterBy('propertyValue.property.code', 'size').get('firstObject.propertyValue.name');
    }),
    finishName: Ember.computed('itemPropertyValues.@each.id', function () {
      return this.get('itemPropertyValues').filterBy('propertyValue.property.code', 'finish').get('firstObject.propertyValue.name');
    })

  });
});