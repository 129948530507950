define('web/adapters/application', ['exports', 'ember-inflector', 'ember-simple-auth-token/mixins/token-authorizer', 'ember-data', 'web/config/environment'], function (exports, _emberInflector, _tokenAuthorizer, _emberData, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPIAdapter.extend(_tokenAuthorizer.default, {
    session: Ember.inject.service(),
    host: `${_environment.default.host}`,
    urlForQuery(query) {
      if (query.resource) {
        let resource = query.resource;
        delete query.resource;
        return `${this._super(...arguments)}/${resource}`;
      }

      return this._super(...arguments);
    },
    pathForType(type) {
      var decamelized = Ember.String.decamelize(type);
      var underscored = Ember.String.underscore(decamelized);
      return (0, _emberInflector.pluralize)(underscored);
    }
  });
});