define('web/services/current-user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service('session'),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    id: Ember.computed.alias('user.id'),
    name: Ember.computed.alias('user.name'),
    customer: Ember.computed.alias('user.customer'),
    load() {
      if (this.get('session.isAuthenticated')) {
        return this.get('store').queryRecord('user', { me: true }).then(user => {
          this.set('user', user);
          if (user.get('domain')) {
            let domain = window.location.href;
            domain = domain.replace('www.loftus.com', user.get('domain'));
            domain = domain.replace('alpha.loftus.com', `a-${user.get('domain')}`);
            domain = domain.replace('beta.loftus.com', `b-${user.get('domain')}`);
            window.location.href = domain;
          }
        });
      } else {
        return Ember.RSVP.resolve();
      }
    }
  });
});