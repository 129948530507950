define('web/components/basic-dropdown-hover', ['exports', 'ember-basic-dropdown-hover/components/basic-dropdown-hover'], function (exports, _basicDropdownHover) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _basicDropdownHover.default;
    }
  });
});