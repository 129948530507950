define('web/routes/orders/history', ['exports', 'web/routes/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    model() {
      return this.get('store').findAll('order');
    }
  });
});