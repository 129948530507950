define('web/services/ajax', ['exports', 'ember-ajax/services/ajax'], function (exports, _ajax) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ajax.default.extend({
    session: Ember.inject.service(),
    headers: Ember.computed('session.authToken', {
      get() {
        let headers = {};
        const authToken = this.get('session.data.authenticated.jwt');
        if (authToken) {
          headers['auth-token'] = authToken;
          headers['Authorization'] = authToken;
        }
        return headers;
      }
    })
  });
});