define('web/models/customer', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    number: _emberData.default.attr('string', { readOnly: true }),
    company: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    address1: _emberData.default.attr('string'),
    address2: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    zip: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    contact: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    fax: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    ivLevel: _emberData.default.attr('number'),
    defaultShipToId: _emberData.default.attr('number'),
    shipTos: _emberData.default.hasMany('ship-to'),
    specialOrderItems: _emberData.default.hasMany('special-order-item'),
    alwaysInflate: Ember.computed('inflates', function () {
      return this.get('inflates') === 'inflates_always';
    }),
    inflatesUnavailable: Ember.computed('inflates', function () {
      return this.get('inflates') === 'inflates_unavailable';
    }),
    reservedItems: _emberData.default.attr({ readOnly: true }),
    noFreightAmount: _emberData.default.attr('number'),
    inflates: _emberData.default.attr('string'),
    chain: _emberData.default.belongsTo('chain'),
    shippingService: _emberData.default.belongsTo('shipping-service'),
    prop65: _emberData.default.attr('boolean'),
    prop65AcknowledgedAt: _emberData.default.attr('date'),
    categoryDiscounts: _emberData.default.hasMany('customer-category-discount'),
    customerItems: _emberData.default.hasMany('customer-item', { inverse: 'customer' }),
    terms: _emberData.default.attr('string', { readOnly: true }),
    newPricing: _emberData.default.attr('boolean', { readOnly: true }),
    disablePurchasing: _emberData.default.attr('boolean'),
    discountFreightAmount: _emberData.default.attr('number'),
    balance: _emberData.default.attr('number', { readOnly: true }),
    lastPaymentOn: _emberData.default.attr('date', { readOnly: true }),
    lastPaymentAmount: _emberData.default.attr('number', { readOnly: true }),
    customerType: _emberData.default.attr('string'),
    paymentAbs: Ember.computed('lastPaymentAmount', function () {
      return Math.abs(this.get('lastPaymentAmount'));
    }),
    prop65Restricted: Ember.computed('prop65', 'prop65AcknowledgedAt', function () {
      return this.get('prop65') && !this.get('prop65AcknowledgedAt');
    }),
    prop65Permitted: Ember.computed('prop65', 'prop65AcknowledgedAt', function () {
      return !this.get('prop65') || this.get('prop65AcknowledgedAt');
    }),
    paymentTokens: _emberData.default.hasMany('payment-token'),
    address: Ember.computed('company', 'name', 'address1', 'address2', 'city', 'state', 'zip', 'country', function () {
      let address = [];
      address.pushObject(this.get('company'));
      if (this.get('name')) {
        address.pushObject(this.get('name'));
      }
      address.pushObject(this.get('address1'));
      if (this.get('address2')) {
        address.pushObject(this.get('address2'));
      }
      address.pushObject(`${this.get('city')}, ${this.get('state')} ${this.get('zip')}`);
      if (this.get('country.length') === 2 && this.get('country').toUpperCase() !== 'US') {
        address.pushObject(this.get('country'));
      }
      return address.join('<br>');
    })

  });
});