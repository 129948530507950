define('web/controllers/backorders', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    shoppingCart: Ember.inject.service(),
    minDate: Ember.computed(function () {
      return new Date();
    }),
    actions: {
      // addToCart: function(alert) {
      //   this.get('shoppingCart').add(alert.get('item'),alert.get('displayQuantity') * 1, alert);
      // },
      destroyBackorder: function (bo) {
        bo.destroyRecord();
      }
    }
  });
});