define('web/routes/search/results', ['exports', 'web/routes/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    ajax: Ember.inject.service(),
    model(params) {
      console.log(params);
      // return this.get('store').query('item',{term: params.term});
      return this.get('ajax').request('api/items?', { data: { es: params.term } });
    }

  });
});