define('web/models/page', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    handle: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    templateSuffix: _emberData.default.attr('string'),
    bodyHtml: _emberData.default.attr('string'),
    imageUrl: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    header: _emberData.default.attr(),
    parent: _emberData.default.belongsTo('page', { inverse: 'pages' }),
    position: _emberData.default.attr('number'),
    articles: _emberData.default.hasMany('article'),
    collapseCollections: _emberData.default.attr('boolean'),
    pageSort: ['position'],
    sortedPages: Ember.computed.sort('pages', 'pageSort'),
    pages: _emberData.default.hasMany('page', { inverse: 'parent' }),
    collections: _emberData.default.hasMany('collection'),
    collectionSort: ['position'],
    sortedCollections: Ember.computed.sort('collections', 'collectionSort'),
    chain: _emberData.default.belongsTo('chain'),
    pageBlocks: _emberData.default.hasMany('page-block'),
    blocks: Ember.computed.sort('pageBlocks', 'pageSort')

  });
});