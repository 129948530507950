define('web/models/order', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    number: _emberData.default.attr('string'),
    billedAt: _emberData.default.attr('date'),
    placedAt: _emberData.default.attr('date'),
    terms: _emberData.default.attr('string'),
    poNumber: _emberData.default.attr('string'),
    subTotal: _emberData.default.attr('number'),
    balance: _emberData.default.attr('number'),
    paid: Ember.computed.not('balance', 0),
    freightAllowance: _emberData.default.attr('number'),
    freight: _emberData.default.attr('number'),
    credit: _emberData.default.attr('number'),
    salesTax: _emberData.default.attr('number'),
    total: _emberData.default.attr('number'),
    orderType: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    invoicedAt: _emberData.default.attr('date'),
    invoiceHash: _emberData.default.attr('string'),
    shipment: _emberData.default.belongsTo('shipment'),
    customer: _emberData.default.belongsTo('customer'),
    orderItems: _emberData.default.hasMany('order-item'),
    cart: _emberData.default.belongsTo('cart'),
    shipStatus: Ember.computed('billedAt', function () {
      return this.get('billedAt') ? 'Shipped' : 'In Process';
    })
  });
});