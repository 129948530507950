define('web/controllers/orders/history', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    unpaid: Ember.computed.filterBy('model', 'paid', false),
    displayOrders: Ember.computed('showUnpaid', 'model', 'unpaid', function () {
      return this.get('showUnpaid') === true ? this.get('unpaid') : this.get('model');
    }),
    actions: {
      toggleUnpaid: function () {
        this.set('showUnpaid', !this.get('showUnpaid'));
      }
    }
  });
});