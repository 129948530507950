define('web/controllers/reset-password/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    canSave: Ember.computed('model.password', 'model.passwordConfirmation', function () {
      return this.get('model.password') && this.get('model.password.length') > 5 && this.get('model.password') === this.get('model.passwordConfirmation');
    }),
    disabled: Ember.computed('canSave', function () {
      return !this.get('canSave');
    }),
    actions: {
      save: function () {
        let _this = this;
        this.get('model').save().then(user => {
          _this.get('notify').info('Password Saved');
          _this.set('model.password', null);
          _this.set('model.passwordConfirmation', null);
        });
      }
    }
  });
});