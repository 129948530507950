define('web/components/list-collection', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    init: function () {
      this._super(...arguments);
      if (!this.get('model.id') && this.get('modelId')) {
        this.set('model', this.get('store').queryRecord('collection', { id: this.get('modelId') }));
      }
    },
    itemSort: Ember.computed('model.sortOrder', function () {
      return (this.get('model.sortOrder') || '').split(',');
    }),
    activeItems: Ember.computed.filterBy('model.collectionItems', 'item.id'),
    sortedItems: Ember.computed.sort('activeItems', 'itemSort')
  });
});