define('web/components/show-cart', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    router: Ember.inject.service(),
    shoppingCart: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    notify: Ember.inject.service(),
    didInsertElement: function () {
      this.get('loadReserved').perform();
    },
    checkPromo: (0, _emberConcurrency.task)(function* () {
      try {
        let promo = yield this.get('store').queryRecord('promo', { code: this.get('promoCode') });
        this.get('shoppingCart').setPromo(promo);
      } catch (e) {
        this.get('notify').error({ html: e.errors.join('<br>'), closeAfter: 5000 });
      } finally {
        this.set('promoCode', null);
      }
    }),
    loadReserved: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(2000);
      let reserved = this.get('shoppingCart.cart.customer.reservedItems');
      console.log(reserved);
    }),
    inflateAction: Ember.computed('shoppingCart.items', function () {
      console.log('Inflate Action');
      console.log(this.get('shopingCart.items').filterBy('inflated').length);
      return this.get('shoppingCart.items').filterBy('inflated').length > 0;
    }),
    actions: {
      save: function () {
        let _this = this;

        if (!this.get('shoppingCart.cart.event.id')) {
          this.set('shoppingCart.cart.event', null);
          this.set('shoppingCart.eventAcknowledged', false);
        }
        this.get('shoppingCart.cart.cartItems').forEach(function (ci) {
          ci.set('quantity', ci.get('quantity') * 1);
        });
        this.get('shoppingCart.items').forEach(function (ci) {
          if (ci.item.get('salesMultiple') && (_this.get('shoppingCart.customer.chain.requireInners') || ci.item.get('forceSalesMultiple'))) {
            let inners = ci.item.get('salesMultiple');
            if (_this.get('shoppingCart.cart.customer.customerType') === 'grocery') {
              inners = ci.item.get('grocerMinimum') || inners;
            }

            if (inners && ci.get('quantity') % inners > 0) {
              ci.set('quantity', Math.ceil(ci.get('quantity') / inners) * inners);
            }
          }
        });
        this.get('shoppingCart.cart.cartItems').filterBy('hasDirtyAttributes', true).invoke('save');
        this.get('shoppingCart.cart').save();
      },
      checkout: function () {
        this.send('save');
        this.get('router').transitionTo('checkout');
      },
      setCancelDate: function (cancelAt) {
        this.set('shoppingCart.cart.cancelBackordersAt', cancelAt);
        this.get('shoppingCart.cart').save();
      },
      remove: function (item) {
        this.get('shoppingCart').remove(item);
      },
      acknowledgeEvent: function () {
        this.set('shoppingCart.eventAcknowledged', !this.get('shoppingCart.eventAcknowledged'));

        if (this.get('shoppingCart.eventAcknowledged')) {
          this.set('shoppingCart.cart.event', this.get('shoppingCart.event'));
        } else {
          this.set('shoppingCart.cart.event', null);
        }

        this.get('shoppingCart.cart').save();
      },
      clearPromo: function () {
        this.set('shoppingCart.cart.promo', null);
        this.get('shoppingCart.cart').save();
      },
      saveQuantity: function () {
        this.get('shoppingCart.items').forEach(function (ci) {
          console.log(ci.get('quantity'));
          console.log(ci.get('quantity') * 1);
          console.log(ci.get('quantity') !== ci.get('quantity') * 1);
          if (ci.get('quantity') !== ci.get('quantity') * 1) {
            ci.set('quantity', ci.get('quantity') * 1);
            ci.save();
          }
        });
      },
      itemSelect: function (result) {
        let _this = this;
        this.get('store').findRecord('item', result.id).then(item => {
          _this.get('shoppingCart').add(item, 1);
        });
      },
      toggleInflates: function () {
        if (this.get('shoppingCart.items').filterBy('inflated').length < this.get('shoppingCart.items').filterBy('canInflate').length) {
          this.get('shoppingCart.items').filterBy('canInflate').forEach(function (ci) {
            ci.set('inflated', true);
          });
        } else {
          this.get('shoppingCart.items').filterBy('canInflate').forEach(function (ci) {
            if (ci.get('item.itemType') !== 3) {
              ci.set('inflated', false);
            }
          });
        }
      }
    }
  });
});