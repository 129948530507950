define('web/mixins/reset-scroll-position', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    activate: function () {
      this._super();
      window.scrollTo(0, 0);
    },
    didInsertElement: function () {
      this._super();
      window.scrollTo(0, 0);
    }
  });
});