define('web/controllers/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    shoppingCart: Ember.inject.service(),
    navHeader: Ember.computed.alias('model.header'),
    actions: {
      toggleLogin: function () {
        this.toggleProperty('showLogin');
      },
      invalidateSession() {
        this.get('session').invalidate();
      },
      setModalItem: function (item) {
        this.set('modalItem', item);
      },
      clearModalItem: function () {
        this.set('modalItem', null);
      }
    }
  });
});