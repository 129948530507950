define('web/routes/pages/show', ['exports', 'web/routes/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    model: function (params) {
      // let pages = this.get('store').peekAll('page');
      // let page = pages.filterBy('handle',params.page_id).get('firstObject');
      // if (page && page.id) {
      //   return this.get('store').peekRecord('page',page.id);
      // }
      window.scrollTo(0, 0);
      return this.get('store').queryRecord('page', { id: params.page_id });
    }
  });
});