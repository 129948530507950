define('web/serializers/application', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPISerializer.extend({
    serializeAttribute(snapshot, json, key, attribute) {
      // do not serialize the attribute!
      if (attribute.options && attribute.options.readOnly) {
        return;
      }
      if (attribute.name == 'createdAt' || attribute.name == 'updatedAt') {
        return;
      }
      this._super(...arguments);
    }
  });
});